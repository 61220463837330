.accounts-list {

    * {
        font-family: "Montserrat" !important;
    }

    .contract-container {
        font-weight: "bold";
        cursor: "pointer";
    }


    .hotels-affected-container {
        display: "flex";
        flex-wrap: "wrap";
        gap: "8px";
    }

    .hotels-tags {
        font-size: "14px";
        padding: "5px 10px"
    }


    .loading-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: x-large;

        span {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    .button-validation-container {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        margin: 0px !important;


    }

    .app-button {
        margin: 0px !important;
    }

    .account-item {
        border: solid 1px;
        margin-bottom: 5px;
    }

    .app-link {
        font-family: 'Courier New', Courier, monospace;
        text-decoration: none !important;
        margin-left: 5px;
        font-size: 14px;
    }

    .statistics-list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .contracts {
        display: flex;
        align-items: center;
        margin-top: 8px;
        font-size: 14px;

        .app-icon {
            margin-right: 5px;
        }
    }

    .body-container {
        justify-content: flex-start;
    }

    .email-truncated {
        display: inline-block;
        max-width: 95%; // Ajusta el ancho según tu diseño
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;

        @media screen and (max-width: 576px) {
            max-width: 100%;
            vertical-align: top;


        }
    }




}