@import '../../../styles/colors';
@import '../../../styles/theme';

.list-communications {



    .icons-switch {
        display: none !important;

        @media (min-width: 576px) {
            display: block !important;
        }

        margin: 8px 0; // Ajusta el margen según sea necesario
    }

    .title {
        font-size: 20px;

        @media (max-width: 700px) {
            font-size: 16px; // Ajusta el tamaño según sea necesario

        }

    }

    .status-container {
        display: flex;
        align-items: center; // Alinea los elementos verticalmente al centro
        width: 100%;

        .status-content {
            display: flex;
            flex-direction: row; // Alinea los elementos en una fila
            align-items: center;
            gap: 8px; // Espaciado entre el tag y el texto
            flex-wrap: wrap; // Permite que los elementos ocupen nuevas líneas si no hay espacio
        }

        .tag-status-news {
            margin-top: 8px;
            padding: 5px 8px;
            font-size: 10px;
            font-weight: bold;
            background-color: #f0f0f0;
            max-width: fit-content;
            display: inline-flex;
            align-items: center;

            @media (max-width: 700px) {
                font-size: 8px; // Ajusta el tamaño según sea necesario
                padding: 3px 6px;
                margin-top: 0px;
            }
        }

        .publish-info {
            display: flex;
            flex-direction: column; // Los textos están uno debajo del otro
            align-items: flex-start;
            margin-left: 8px; // Espaciado entre el tag y los textos

            .title-date,
            .date {
                font-size: 10px;
                margin: 0;
            }

            .title-date {
                color: grey;
            }

            .date {
                color: black;
            }
        }
    }


    .actions-container {
        display: flex;
        align-items: right; // Centrado vertical
        justify-content: flex-end; // Alinea los elementos al final
        margin-top: 10px !important;
        padding-left: 3px;
        gap: 2px;
        ;

        .featured-icon {
            font-size: 24px;
            color: #faad14;
            cursor: pointer;
            align-items: flex-start;
            padding: 4px !important;


            @media (max-width: 768px) {
                font-size: 16px; // Ajusta el tamaño según sea necesario
                padding-right: 0px !important;
                padding-top: 8px !important;
            }
        }

        .three-dots-icon {
            display: flex;
            align-items: flex-start;
            font-size: 24px;
            font-weight: bold;


            @media (max-width: 700px) {
                font-size: 16px; // Ajusta el tamaño según sea necesario
            }
        }
    }

    .container-info-news {
        padding-left: 10px;
    }


    .avatar-list-news {
        width: 100%; // Se ajusta al contenedor
        height: 100%;
        aspect-ratio: 3 / 1; // Relación de aspecto panorámica
        object-fit: cover; // Recorta la imagen si no cumple la proporción exacta
        padding: 10px;

        border-radius: $app-border-radius;
        align-items: center !important;

        @media (max-width: 1250px) {
            aspect-ratio: 3 / 1; // Mantén la proporción
            width: 100%; // Ajusta a un porcentaje del contenedor
            margin-top: 10px;
        }

        @media (max-width: 767px) {
            aspect-ratio: 3 / 1;
            width: 100%;
            padding: 10px;
        }
    }


    .language-buttons-container {
        display: flex;
        overflow-x: auto; // Habilita desplazamiento horizontal
        white-space: nowrap; // Evita que los botones se rompan en líneas
        gap: 8px;
        margin-top: 10px;
        padding-bottom: 3px;

        &::-webkit-scrollbar {
            height: 3px; // Estiliza la barra de desplazamiento
        }

        &::-webkit-scrollbar-thumb {
            background-color: $primary-client-color;
            border-radius: $app-border-radius;
        }
    }


    .language-button {
        width: auto;
        font-family: 'Montserrat';
        font-size: 12px;
        padding: 8px 16px;
        border-radius: $app-border-radius;
        background-color: #f0f0f0;
        color: #000;
        border: 1px solid #d9d9d9;
        transition: all 0.2s ease;

        &:hover {
            background-color: $secondary-client-color;
            color: #fff; // Texto blanco en hover
        }

        &.ant-btn-primary {
            background-color: $primary-client-color; // Fondo primario
            color: #fff; // Texto blanco
            border-color: $primary-client-color;

            &:hover {
                background-color: $primary-client-color;
                border-color: $primary-client-color;
            }
        }

        &.ant-btn-default {
            background-color: #f0f0f0;
            color: #000;
            border-color: #d9d9d9;

            &:hover {
                background-color: $secondary-client-color;
                color: #fff;
            }
        }
    }

    .news-item {
        background-color: white; // Fondo blanco
        margin-bottom: 20px; // Espaciado entre los items
        border-radius: $app-border-radius; // Bordes redondeados para un diseño más agradable
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); // Sombra para destacar los elementos
        padding: 20px; // Espaciado interno
        border: 1px solid #f0f0f0; // Bordes sutiles para separarlos visualmente

        &:last-child {
            margin-bottom: 0; // Evita margen en el último elemento
        }

        @media (max-width: 700px) {
            padding: 25px;

        }
    }


}