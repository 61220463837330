@import "../../../styles/theme";

.accounts-page{
    font-family: 'Montserrat';
    padding: 20px 40px;

    @media screen and (max-width: 768px){
        padding: 20px 10px;
    }

    .app-card{
        .ant-card-head{
            @media screen and (max-width: 768px){
                padding: 0;
            }
        }
        
    }

    h3{
        text-transform: uppercase;
        font-size: 21px;
        small{
            font-size: 16px;
            font-weight: 400;
            margin: 1em 0;
            text-transform: none;
            display: block;
            clear: both;
        }
    }

    

    .header-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: 'Montserrat';

        @media screen and (max-width: 768px){
            padding-left: 12px;
            padding-right: 12px;
        }

        .account-type{
            display: flex;
            align-items: center;
            @media screen and (max-width: 768px){
                max-width: 100%;
                overflow: auto;
                margin-top: 10px;
                margin-bottom: 10px;
            }
            span:first-child{
                div{
                    display: flex;
                }
            }

            span{
                padding: 0 10px;

                small{
                    display: block;
                    font-size: 12px;
                    font-weight: 400;
                }
            }
        }

        .actions-container{
            display: flex;
            align-items: center;
            justify-content: flex-end;

            @media screen and (max-width: 768px){
                justify-content: flex-end;
                padding: 5px 0;
                flex: 1 1;
                width: 100%;
            }
    
            svg{
                transition: all 0.3s ease-in-out;
                &:hover{
                    cursor: pointer;
                    color: $secondary-client-color !important;
                    fill: $secondary-client-color !important;
                    path{
                        color: $secondary-client-color !important;
                        fill: $secondary-client-color !important;
                        transition: all 0.3s ease-in-out;
                    }
                }
            }
    
        }
    }

    .body-container{
        padding-left: 10px;
        font-family: 'Montserrat';
        display: flex;
        align-items: flex-start;
        gap: 15px;
        
        .additional-info{
            display: flex;
            justify-content: space-between;
            gap: 20px;
            flex: 1 1;

            @media screen and (max-width: 768px){
                flex-direction: column;
            }


            .names{
                display: flex;
                flex-direction: column;
            }
    
            .contact,
            .resume{
                text-align: right;
                display: flex;
                flex-direction: column;

                @media screen and (max-width: 768px){
                    text-align: left;
                }
            }

            .contact{
                text-align: left;
            }

            .names,
            .statistics,
            .contact,
            .contract,
            .resume{
                flex: 1 1;
            }

        }

        
    }



    .app-button{
        margin-top: 2em;
        width: 100%;

        @media screen and (max-width: 768px){
            margin-top: 0em;
            margin-bottom: 2em;
        }
        
    }
}