

.account-manage{
    .account-manage-header{
        display: flex;
        gap: 5px;
    }

    .account-manage-content{
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
        padding-left: 22px;
    }

    .account-manage-footer{
        display: flex;
        justify-content: center;
        gap: 10px;
        margin-top: 30px;
    }
}