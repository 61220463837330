.news-details-page {
    padding: 20px 40px;
   

    .container {
        display: 'flex';
        justify-content: 'flex-start';
        align-items: 'center';   
    }
    .icon{
        font-size: 20px;
        margin-left:4px;
        margin-right: 8px;
    }
    .container-right {
        display: flex;
        align-items: center;
        justify-content: flex-start; // Alineación izquierda por defecto
    
        @media (min-width: 768px) {
            justify-content: flex-end ; // Alineación derecha en pantallas grandes
        }
    }

    

    .container-right-2 {
        display: flex;
        align-items: center;
        justify-content: flex-end !important;; // Alineación izquierda por defecto

        
        @media (min-width: 768px) {
            justify-content: flex-end ; // Alineación derecha en pantallas grandes
        }
    }
    

    .details-text{
        font-family: 'Montserrat' !important;
        font-size: 10px;

    }
    .back-link {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        text-decoration: none;
        /* Asegura que el texto no tenga subrayado por defecto */
        color: inherit;
        /* Mantiene el color actual */
        transition: text-decoration 0.2s ease;
        /* Suaviza la transición */
    }

    .back-link:hover {
        text-decoration: underline;
        /* Añade subrayado al pasar el ratón */
    }

    .back-button {
        margin-right: 8px;
        /* Espaciado entre el ícono y el texto */
    }

    .app-title.large {
        &:not(.no-bordered)::after {
            content: none; // Evitar el subrayado
        }
    }

    .app-title {
        display: flex;
        justify-content: left;
        /* Centra horizontalmente */
        align-items: left;
        /* Centra verticalmente */
        text-align: left;
        /* Asegura que el texto esté centrado */
        width: 100%;
    }



}