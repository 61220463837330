.code-editor {
  .container {
      display: flex;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 20px;
  }

  .preview-iframe {
      width: 100%;
      height: 600px; // Aumenta la altura del iframe para una vista más grande
      border: none;
      margin-bottom: 20px;
  }

  h2 {
      margin: 0;
      margin-right: 10px;
  }

  .editor-wrapper {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
  }

  .editor-button {
      margin-top: 10px;
  }


  .button-container {
      display: flex !important;
      justify-content: center !important; // Centra horizontalmente el contenido
  }

  .render-button {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      @media screen and (max-width: 1000px) {
          width: 100%;
          margin-top: 20px;
          margin-bottom: 20px;
      }
  }

  .card-style {
      height: 650px; // Ajusta la altura del Card para hacer coincidir con el iframe
      overflow: auto; // Añade scroll si el contenido supera la altura
  }

  .button-group {
      display: flex;
      justify-content: right;
      justify-items: right;
      align-items: right;
      margin-top: 10px;
  }

  .add-button, .delete-button {
      margin-top: 10px;
      margin-bottom: 20px;
      margin-left: 10px;
      width: 20%;
      @media screen and (max-width: 1320px) {
          width: 50%;
      }
  }
}
