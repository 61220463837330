.tag-status-news {
    padding: 5px 8px;
    font-family: 'Montserrat';
    margin: 15px 0;
    max-width: fit-content; // Ocupa solo lo necesario
    display: inline-flex; // Alineación interna
    align-items: center;
    font-weight: bold;
    background-color: #f0f0f0; // Fondo opcional para resaltar el tag

    span.anticon {
        font-size: 18px;
    }

    span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: bold;
    }

    @media (max-width: 700px) {
        align-items: center;
    }

   
}