.hotels-form-component {
    margin-left: 20px;
    margin-right: 20px;

    .input-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 8px;
    }

    .switch-container {
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: left;
        text-align: left;
    }

    .select-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
    }

    .notBold {
        font-weight: normal;
    }

    .multiple-checks-container {
        gap: 5px;
        display: flex;
        flex-direction: column;
    }

    .checkbox-sync-container {
        display: flex;
        align-items: left;
        justify-content: left;
        text-align: left;
        margin-top: 20px;
        margin-bottom: 40px;
    }

    // Clase scrollable para agregar el desplazamiento
    .scrollable {
        max-height: 120px; // Ajusta esta altura según lo que necesites
        overflow-y: auto;  // Activa el scroll vertical
        padding-right: 10px;  // Espacio adicional para la barra de scroll
    }
}
