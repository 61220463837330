@import '../../../styles/theme';

.dots-container{
    .dots-actions{
        min-width: 150px;
        position: absolute;
        border-radius: $app-border-radius;
        right: 25px;
        padding: 4px;
        background-color: white;
        box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

        &.visible{
            display: block;
        }

        &.hidden{
            display: none;

        }

        ul{
            list-style: none;
            padding: 0;
            margin: 0;

            li{
                font-family: 'Montserrat';
                font-weight: 400;
                cursor: pointer;
                margin-bottom: 5px;
                padding: 2.5px 5px;
                color: $primary-client-color;
                font-size: small;
                border-radius: $app-border-radius;
                &:hover{
                    background-color: rgba(0, 0, 0, 0.04);
                    
                }
            }
        }
    }
}