@import "../../styles/theme";



.pagination-component{
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    padding-top : 10px;

    .pagination-resume{
        font-family: 'Montserrat';
        span{
            color: $primary-client-color;
            font-weight: bold;
        }
    }

    .pagination-antd{
        flex: 1 1;
        text-align: right;
    }
}