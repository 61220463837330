.accounts-list{

    .loading-container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: x-large;
        span{
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
    
    .account-item{
        border: solid 1px; 
        margin-bottom: 5px;
    }

    .confirm-status {
        background-color: #007bff; 
        color: white;
        padding: 2px 6px;
        border-radius: 4px;
        margin-right: 10px; /* Separación del Switch */
        display: inline-block; /* Alineación en línea con el switch */
    }
    
}