.language-selector-news {
  display: flex;
    justify-content: flex-start; // Alinea a la izquierda
    align-items: center; // Alinea verticalmente
    width: auto; // Permite que el ancho dependa del contenido
    
    .language {
      width: auto; // Ancho basado en el contenido
      .ant-select-selector {
          display: flex;
          align-items: center; // Centrado vertical
          justify-content: flex-start; // Centrado horizontal
      }
  }
  
  
}


