.tab-configuration-component {
  * {
    font-family: "Montserrat" !important;
  }

  .panel-to-up {
    margin-top: -40px;
    width: 100%
  }

  .ant-tabs-dropdown {
    z-index: 999
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--primary-app-color);
  }


  .ant-tabs-ink-bar {
    &.ant-tabs-ink-bar-animated {
      background-color: var(--primary-app-color) !important;
    }
  }

  .ant-tabs-nav-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Montserrat';
    padding: 12px 0;
    height: 100%;
    width: 100%;
    margin-left: 80px;
    margin-right: 80px;
  }

  .ant-tabs-tab {
    width: 50%;
    justify-content: center;
  }

  .pro-configuration {
    margin-top: 20px;
    margin-left: 50px;
    margin-right: 50px;

  }

}