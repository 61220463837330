@import '../../../styles/theme';


.main-menu {
    &.desktop, &.tablet{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 88px; 
        margin-right: auto; 
        visibility: visible;
    
        &.vertical{
            flex-direction: column;
        }
        
    
        &.mobile{
            display: none;
            visibility: hidden;
        }
    }

    &.mobile{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: auto; 
        visibility: visible;

        &.vertical{
            flex-direction: column;
        }

        .app-link{
            margin-top: 25px;
            display: flex;
            width: 100%;
            cursor: pointer;
            .app-icon{
                margin-right: 10px;
                margin-left: 20px;
                height: 20px;
                width: 20px;
            }
        }
    }

}


.public-header{
    &.desktop,
    &.tablet{
        position: fixed;
        width: 100%;
        /* Establece un estilo base para el menú superior */
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 20px;
        color: white;
        background: #FFF;
        box-shadow: 0px 4px 12px -2px rgba(0, 0, 0, 0.21);
        height: $header-height;
        z-index: 10;
        max-width: 100% !important;

        .menu-icon{
            font-size: 24px;
        }

        .app-icon{
            cursor: pointer;
        }

        a{
            cursor: pointer;
            font-family: Lato;
            font-size: $main-size-text;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.08px;
            text-transform: uppercase;
            text-decoration: none;
        }

        /* Estilo para el logotipo */
        .logo {
            cursor: pointer;
            min-width: 212px;
            flex-shrink: 0;
            height: 100%;
            display: flex;
            align-items: center;
            margin-right: 47px;

            &.mobile{
                margin-right: 0;
            }

            img {
                max-width: 200px; /* La imagen se ajustará al ancho del contenedor */
                height: auto; /* La altura se ajustará proporcionalmente */
            }
        }

        /* Estilo para el menú de usuario */
        .user-menu {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 25px; /* Espacio entre elementos del menú de usuario */
        }

        
    }

    &.mobile{
        position: fixed;
        overflow: hidden;
        max-width: 100vw !important;
        width: 100vw !important;
        /* Establece un estilo base para el menú superior */
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        color: white;
        background: #FFF;
        box-shadow: 0px 4px 12px -2px rgba(0, 0, 0, 0.21);
        height: $header-height;
        z-index: 10;
    
        .menu-button{
            padding: 10px;
        }
    
        .menu-icon{
            font-size: 24px;
        }
    
        .app-icon{
            cursor: pointer;
        }
    
        a{
            cursor: pointer;
            font-family: Lato;
            font-size: $main-size-text;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.08px;
            text-transform: uppercase;
            text-decoration: none;
        }
    
        /* Estilo para el logotipo */
        .logo {
            cursor: pointer;
            min-width: 212px;
            flex-shrink: 0;
            height: 100%;
            display: flex;
            align-items: center;
            margin-right: 47px;
    
            &.mobile{
                margin-right: 0;
            }
    
            img {
                max-width: 200px; /* La imagen se ajustará al ancho del contenedor */
                height: auto; /* La altura se ajustará proporcionalmente */
            }
        }
    
        /* Estilo para el menú de usuario */
        .user-menu {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 20px;
        }

        
    }
}


