$colors: (
    primaryClientColor: var(--primary-app-color),
    secondaryClientColor: var(--secondary-app-color)
);

$primary-client-color: map-get($colors, primaryClientColor); 
$secondary-client-color: map-get($colors, secondaryClientColor); 

$caledar-primary-color: red;


// ---------COLORS---------
$blue: #04BBC2;
$red: #F24848;
$yellow: #ECC733;
$pink: #F1D7D7;
$magent: #DE597D;
$dark-green: #249B64;
$green: #76A232;

$black: #1D2328;
$dark-gray: #343940;
$gray: #707070;
$medium-gray: #AAAAAA;
$light-gray: #F4F6F7;

$white: #fff;

$transparent: rgba(#fff, 0);

// ---------BREAK POINTS---------
$mobile: 320px;
$phablet: 568px;
$tablet: 768px;
$laptop: 1024px;
$desktop: 1440px;
$smartTV: 1920px;


// Definición del mixin
@mixin breakpoint($point) {
    @if $point == mobile {
      @media (max-width: 767px) {
        @content;
      }
    }
  
    @if $point == tablet {
      @media (min-width: 768px) and (max-width: 1023px) {
        @content;
      }
    }
  
    @if $point == laptop {
      @media (min-width: 1024px) and (max-width: 1279px) {
        @content;
      }
    }
  
    @if $point == desktop {
      @media (min-width: 1280px) {
        @content;
      }
    }
  }

  // Definición de la función size
@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
  }
  
