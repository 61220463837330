@import './../../../styles/theme';

.dashboard-page{
    font-family: 'Montserrat';
    padding: 20px;

    .title{
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.2px;
    }

    .sub-title{
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.2px;
        margin-bottom: 2em;
    }

    .benefits{
        list-style: none;
        padding: 0;

        .benefit-item{
            display: flex;
            align-items: start;
            margin-bottom: 2em;
            img{
                max-width: 24px;
                max-height: 24px;
            }

            div{
                padding-left: 20px;
                h3{
                    margin-top: 0;
                    color: #000;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.2px;
                    text-transform: uppercase;
                }
    
                p{
                    color: #000;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.2px;
                }

                .contract-benefit{
                    list-style-type: disc;
                    margin-bottom: 2em;
                }

                button{
                    margin-right: 10px;
                }
    
                button:not(.large){
                    width: 171px;
                    height: 32px;
                    flex-shrink: 0;
                    background-color: transparent !important;
                    color: $primary-client-color !important;
    
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    letter-spacing: -0.2px;

                    &:hover{
                        background-color: $primary-client-color !important;
                        border: solid 1px $primary-client-color !important;
                        color: #fff !important;
                    }
                }
            }

            
        }
    }
}