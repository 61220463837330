.modal-background,
.booking-engine-page {


    button {
        outline: none;
        border: none;
        box-sizing: border-box;
        cursor: pointer;
    }
    
    span {
        vertical-align: middle;

        &:not(.sol-icon) {
            margin: 0 5px;
        }
    }

    .ant-steps-item-icon{

        span{
            vertical-align: middle !important;
            margin: 0 0 5px 0 !important;
        }

        .ant-steps-icon:not(:has(.ant-steps-finish-icon)){
            display: flex;
            height: 22px;
            align-items: center;
            justify-content: center;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }


    .modal-content {
        z-index: 1;
        position: relative;
        padding: 20px 30px;
        max-width: 100%;
    }

    .modal-background {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 9;
        padding: 20px;
        animation: fadeEnter 0.3s ease-in-out;
    }

    .modal-foreground {
        height: 100%;
        width: 100%;
        max-height: 100%;
        max-width: 100%;
        border-radius: 10px;
        display: flex;
        justify-content: center;
    }

    input[type="color"],
    input[type="date"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="text"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    input:not([type]),
    textarea {
        border: none;
        outline: none;
        box-shadow: none;
        box-sizing: border-box;
        margin: 0;
        padding: 15px 20px;
        transition: background-color 0.3s ease-in-out;
        min-width: 0;
    }


}