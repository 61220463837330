@import '../../../styles/theme';

.error-403-page{
    height: calc(100vh - 70px - 55px);
    &.generic-message{
        display: flex;
        
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        .icon{
            margin-bottom: 32px;
        }

        .number{
            
            font-size: 3.5em;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 6px;
        }

        .message{
            margin-bottom: 60px;


            .title{
                font-size: 1.5em;
                font-weight: 600;
                margin-bottom:0;
            }
            .text {
                margin-top: 0;
                font-size: 1em;
                margin-top: 5px;
                margin-bottom: 0;
            }
            
            .text:first-child {
                font-size: 1em;
                margin-top: 125px;
                margin-bottom: 0;
            }
            
        }


        .buttons{
            display: flex;
            flex-direction: row; /* Por defecto, los botones estarán uno al lado del otro */
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            button{
                width: 300px;
                height: 60px;
                color: #FFF;
                font-family: Lato;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.08px;
                border-radius: 0;
                margin: 0 5px 28px 5px;
                border: none;
                
                .content{
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    span{
                        margin-left: 13px;
                    }
                }
            }
        }
    }
}