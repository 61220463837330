.input-labeled-component{
    max-width: 99%;
    


    /*Reposo*/
    label{
        background-color: white;
        display: block;
        width: 100%;
        border: 1px solid silver;
        border-radius: 3px;
        position: relative;
        height: 40px;
        margin-bottom: 40px;

        span{
            color: silver;
            opacity: 0.5;
            font: 16px/16px 'Montserrat';
            position: absolute;
            top: 12px;
            left: 17px;
        }
    }

    /*Focus*/
    label.focus{
        border-color: #b1b1b1;

        span.focus{
            opacity: 1;
            color: #b1b1b1;
            transition: all .2s;
        }
    }

    /*Top*/
    label.top{
        span.top{
            opacity: 1;
            left: 8px;
            top: -17px;
            font-size: 12px;
            border-width: 0 0 1px 0;
            border-radius: 3px 3px 0 0;
            padding: 0 5px;
            color: #797979;
            transition: all .2s;
        }
    }

    input{
        display: block;
        border: none;
        background: transparent;
        outline: none !important;
        border-color: transparent;
        box-shadow: none !important;
        padding: 10px 15px;
        font: 16px/16px 'Montserrat';
    }
}