.landing-page-component{

    margin-top: 20px;
    margin-left: 100px;
    margin-right: 100px;
    font-family: Montserrat;

    .button-container{
        display: flex;
        align-items: right;
        justify-content: right;
    }
    .select-large{
        width: 100%;
    }

    .btn-save{
        margin-top: 20px;
    }
   
    
    .button-large {
        width: 20%;

        @media screen and (max-width: 768px){
            width: 100%;
            margin-top:  20px;
        }
    }

    .flex-column{
        display: flex;
        flex-direction: column;
    }
}