$font-path: '../assets/fonts/';

@font-face {
  font-family: 'Lato';
  src: url('#{$font-path}Lato//Lato-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('#{$font-path}Lato//Lato-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('#{$font-path}Lato//Lato-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('#{$font-path}Lato//Lato-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('#{$font-path}Lato//Lato-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('#{$font-path}Lato//Lato-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('#{$font-path}Lato//Lato-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('#{$font-path}Lato//Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('#{$font-path}Lato//Lato-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('#{$font-path}Lato//Lato-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}


  /* Roboto Black */
@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  font-style: normal;
  src: url('#{$font-path}Roboto/Roboto-Black.ttf') format('truetype');
}

/* Roboto Black Italic */
@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  font-style: italic;
  src: url('#{$font-path}Roboto/Roboto-BlackItalic.ttf') format('truetype');
}

/* Roboto Bold */
@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: normal;
  src: url('#{$font-path}Roboto/Roboto-Bold.ttf') format('truetype');
}

/* Roboto Bold Italic */
@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: italic;
  src: url('#{$font-path}Roboto/Roboto-BoldItalic.ttf') format('truetype');
}

/* Repite el mismo patrón para los otros estilos de fuente */

/* Roboto Italic */
@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: italic;
  src: url('#{$font-path}Roboto/Roboto-Italic.ttf') format('truetype');
}

/* Roboto Light */
@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  src: url('#{$font-path}Roboto/Roboto-Light.ttf') format('truetype');
}

/* Roboto Light Italic */
@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: italic;
  src: url('#{$font-path}Roboto/Roboto-LightItalic.ttf') format('truetype');
}

/* Roboto Medium */
@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  src: url('#{$font-path}Roboto/Roboto-Medium.ttf') format('truetype');
}

/* Roboto Medium Italic */
@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: italic;
  src: url('#{$font-path}Roboto/Roboto-MediumItalic.ttf') format('truetype');
}

/* Roboto Regular */
@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  src: url('#{$font-path}Roboto/Roboto-Regular.ttf') format('truetype');
}

/* Roboto Thin */
@font-face {
  font-family: 'Roboto';
  font-weight: 100;
  font-style: normal;
  src: url('#{$font-path}Roboto/Roboto-Thin.ttf') format('truetype');
}

/* Roboto Thin Italic */
@font-face {
  font-family: 'Roboto';
  font-weight: 100;
  font-style: italic;
  src: url('#{$font-path}Roboto/Roboto-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 100;
  font-style: normal;
  src: url('#{$font-path}Montserrat/Montserrat-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 200;
  font-style: normal;
  src: url('#{$font-path}Montserrat/Montserrat-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  font-style: normal;
  src: url('#{$font-path}Montserrat/Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: normal;
  src: url('#{$font-path}Montserrat/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  font-style: normal;
  src: url('#{$font-path}Montserrat/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  font-style: normal;
  src: url('#{$font-path}Montserrat/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  font-style: normal;
  src: url('#{$font-path}Montserrat/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 800;
  font-style: normal;
  src: url('#{$font-path}Montserrat/Montserrat-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 900;
  font-style: normal;
  src: url('#{$font-path}Montserrat/Montserrat-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 100;
  font-style: italic;
  src: url('#{$font-path}Montserrat/Montserrat-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 200;
  font-style: italic;
  src: url('#{$font-path}Montserrat/Montserrat-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  font-style: italic;
  src: url('#{$font-path}Montserrat/Montserrat-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: italic;
  src: url('#{$font-path}Montserrat/Montserrat-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  font-style: italic;
  src: url('#{$font-path}Montserrat/Montserrat-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  font-style: italic;
  src: url('#{$font-path}Montserrat/Montserrat-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  font-style: italic;
  src: url('#{$font-path}Montserrat/Montserrat-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 800;
  font-style: italic;
  src: url('#{$font-path}Montserrat/Montserrat-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 900;
  font-style: italic;
  src: url('#{$font-path}Montserrat/Montserrat-BlackItalic.ttf') format('truetype');
}
