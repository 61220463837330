.public-footer{
    height: 60px;
    background: #282828;
    color: white;
    font-family: Lato;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    
    .menu{
        display: flex;
        justify-content: center;

        .menu-link-element{
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.08px;
            text-decoration: none;
        }
    }
}