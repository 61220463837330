.inline-language-selector{
    ul{
        display: flex;
        list-style: none;
        padding: 0;
        
        li{
            cursor: pointer;
            text-decoration: underline;
            padding-bottom: 2px;
            margin-bottom: 2em;
            font-size: 12px;
            font-weight: 400;
            color: #61758A;

            &:hover{
                color: #45494d;
            }


            &::after{
                content: "|";
                text-decoration: none !important;
                margin: 0 5px;
            }

            &:last-child{
                &::after{
                    content: "";
                    margin: 0;
                }
            }
        }
    }
}