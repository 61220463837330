.regimes-config-component {

    h2 {
        font-weight: bold;
        margin-top: 50px;
    }

    .button-group {
        display: flex;
        justify-content: center;
        gap: 50px;
        margin-bottom: 20px;
    }


    .table {
        border-collapse: collapse;
        margin-top: 20px;
    }

    .button-class {
        align-items: center; 
        width: 200px;
        background-color: var(--primary-app-color);
        border-radius: 0;
        margin-left : 20px;
    }

    .button-container {
        display:flex;
        justify-content: center;
    }

    .button-import {
        align-items: center; 
        width: 200px;
        background-color:var(--secondary-app-color);
        border-radius: 0;
        margin-left : 20px;
    }


}
 
