.icon-hotelTable-container  {
    display: flex;
    justify-content: center;

    svg {
        margin-right: 5px;
        margin-left: 5px;
    }
}

.hotels-config-component {
    

    h2 {
        font-family: Montserrat;
        font-weight: bold;
        margin-top: 50px;
    }

    .button-group {
        display: flex;
        justify-content: center;
        gap: 50px;
        margin-bottom: 20px;
    }


    .table {
        border-collapse: collapse;
        margin-top: 20px;
        font-family: 'Montserrat' !important;
    }
    
    @media (max-width: 575.98px) { /* Tamaño XS */
        .table .ant-table-thead > tr > th,
        .table .ant-table-tbody > tr > td {
            padding: 4px; /* Ajusta el padding según sea necesario */
            font-size: 12px; /* Ajusta el tamaño de la fuente según sea necesario */
        }
    }









    .icon-2 {
        font-size: 20px;
        margin-left: 10px;
    }

    .button-class {
        align-items: center;
        font-family: Montserrat;
        width: 200px;
        background-color: var(--primary-app-color);
        border-radius: 0;
        margin-left: 20px;
    }

    .button-container {
        display: flex;
        justify-content: center;

    }

    .button-import {
        align-items: center;
        font-family: Montserrat;
        width: 200px;
        background-color: var(--secondary-app-color);
        border-radius: 0;
        margin-left: 20px;
    }


}