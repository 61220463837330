@import '../../../styles/theme';

.communications-page{
    padding: 20px 40px;
    * {
        font-family: "Montserrat" !important;
    }

    .title{
        margin:0px;
    }
    .text {
        padding-bottom:12px;
    }
    .app-button{
        width: 100%;
    }

    .pagination-container{
        padding: 20px 0px;
    }
    .container {
        margin-top: 20px;
    }

}