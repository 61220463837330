@import '../../../styles/texts';

.language-selector{

    text-align: left;

    .ant-select-selection-item{
        color: #666;
        font-family: Lato;
        font-size: $main-size-text;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.08px;
    }

    min-width: 150px;
    width: 100%;

    .ant-select-selector{
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        border: none !important;
    }

    .ant-select-arrow{
        font-weight: bold;
    }
}