@import "../../../styles/theme";

.communications-filter{
    .app-button {
        margin-top: 0; // Asegura que no haya márgenes adicionales
        width: 100%; // Opcional: Ajusta el ancho del botón para llenar el espacio
    }

    .ant-row {
        display: flex;
        align-items: left; // Alinea todos los elementos al fondo de la fila
    }

}