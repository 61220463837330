.edit-contract{

    .alert-base-message{
        margin-bottom: 20px;
    }

    .actions{
        width: 100%;
        display: flex;        
        justify-content: center;
        gap: 10px;
        margin-top: 20px;

        button{
            min-width: 100px;
        }
    }
}