@import '../../../styles/theme';
@import '../../../styles/colors';

.account-info{
    display: flex;
    flex-direction: column;

    label{
        font-size: 14px;
        font-weight: 600;     
        margin-bottom: 5px;
    }
}
.account-assignation-component{
    width: 100%;

   

    .commissions-configuration-list{
    

        .loading-container{
            display: block !important;
            justify-content: center;
            margin-top: 20px;
        }
        .commission-title{
            display: flex;
            align-items: center; // Asegura que los elementos estén alineados verticalmente en el centro
            justify-content: space-between; // Separa los elementos en los extremos (opcional)
        }
    
       
    
        
        .commission-container {
            background-color: white;
            padding: 20px;
            border-radius: $app-border-radius ;
            border: solid 1px $app-border-color;
            margin-top:20px;
        }
        
        .buttons-container {
            padding-top:10px;
            display: flex; /* Coloca los botones uno al lado del otro */
            justify-content: flex-end; /* Alinea los botones a la derecha */
            align-items:flex-end;
            gap: 20px; /* Espacio entre botones */
            margin-top: auto; /* Asegura que se posicionen en la parte inferior */
        }
        
        .app-button {
            flex: 1; /* Hace que los botones ocupen el mismo ancho */
            max-width: 100px; /* Limita el ancho máximo para evitar que crezcan demasiado */
            text-align: center; /* Centra el texto dentro del botón */
            @media screen and (max-width: 768px) {
                max-width: 100%; /* En pantallas pequeñas, los botones ocupan todo el ancho */
                margin-top: 20px;;
                
            }
        }
        
    
    
        
    
        h3 {
            display: inline-block !important;
            ;
            align-items: flex-start !important;
            margin-top: 0px !important;
        }
        
    
        .edit-icon-style {
            font-size: 16px;
            margin-left: 10px;
            cursor: pointer;
            color: $primary-client-color;
        }
    
        .feedback-container {
            padding: 10px 20px !important;
            display: block; // O flex si quieres controlar la alineación de los hijos
            background-color: white;
            border-radius: $app-border-radius;
            border: solid 1px $app-border-color;
            word-wrap: break-word; // Permite cortar palabras largas
            overflow-wrap: break-word; // Compatible con textos largos
            white-space: normal; // Permite que el texto ocupe varias líneas si es necesario
        }
        
        .feedback-title-style{
            font-size: 14px;
        }
    
    
    
    }
}
