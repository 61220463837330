.regimes-form-component{
    margin-left: 20px;
    margin-right: 20px;


    .input-container{
        display: flex;
        flex-direction: column;
        margin-bottom: 8px;
    }
}