.modal-generic {
    margin-top: 8px;
   
  
    .box-modal > .ant-modal-content > .ant-modal-footer {
      text-align: left !important;
    }
  
    .box-modal > .ant-modal-content > .ant-modal-header {
      text-align: center !important;
    }
  
    .box-modal > .ant-modal-content > .ant-modal-body {
      padding: 19px !important;
    }
  
    .body-modal {
      display: flex;
       font-family: 'Montserrat';
        text-align: center;
    }
  
    .ant-modal {
      max-width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .button-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .delete-button {
      width: 100%;
      background-color: #F78C75;
      margin-top: 30px;
    }
  }
  