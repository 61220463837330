.pro-configuration-component {
    margin-left: 100px;
    margin-right: 100px;

    .checkbox-container {
        display: flex;
        margin-bottom: 20px;
    }


    .p-colorpicker.p-component.p-colorpicker-overlay {
        height: 10px;
        width: 10px;
    }

    input.p-colorpicker-preview.p-inputtext {
        margin-left: 10px;
        height: 20px;
        width: 20px;
    }
    
    .app-title{
        margin-top: 50px;
    }
    .button-container {
        display: flex;
        justify-content: center;
    }


    .button-large {
        width: 40%;
        margin-top: 50px;
        @media screen and (max-width: 768px){
            width: 100%;
            margin-top:  20px;
        }
    }


    .select-left {
        display: flex; // si lo quiero a la izquierda sería borrar ésta y la siguiente línea 

    }

    .select-large {
        width: 200px;

    }

    .image-config{
            max-width: 200px;
            max-height: 450px;
            
    }

    .radio-wrapper {
        display: flex;
        align-items: center;
    }
    
    .radio-content {
        flex: 1; /* El contenido del radio se expandirá para llenar el espacio */
    }
    
    .image-container {
        margin-top: 10px; /* Espacio entre el texto y la imagen */
    }

    .text {
        margin-bottom: 10px;
        margin-top:10px
    }
    .radio{
        margin-top: 40px;
    }

    
  
}