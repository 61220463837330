.list-register-form{
    margin-top: 8px;

    .additional-info{
        display: flex;
        div{
            flex: 1;
        }
    }

    .header-container{
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 768px){
            flex-direction: column-reverse;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }

}