.register-form-filter{

    .export-data-content{
        width: 100%;


        .export-button-dropdown{
            display: flex;
            justify-content: flex-end;
    
        }
    }
}