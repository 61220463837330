.validate-account-form-component{

    .button-style{
        width: 100%;
    }
    .contract-selector{
        text-align: center;
        width: 100%;
    }

    
}