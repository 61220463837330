.loading-data-component{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-top: 3em;

    .app-label{
        margin-top: 2em !important;
    }
}