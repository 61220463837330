@import '../../../styles/theme';

.private-header-component{
    height: $header-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    background-color: #333333;
    box-shadow: 0px 4px 12px -2px rgba(0, 0, 0, 0.21);
    z-index: 1;

    /* Estilo para el logotipo */
    .logo {
        cursor: pointer;
        min-width: 212px;
        flex-shrink: 0;
        height: 100%;
        display: flex;
        align-items: center;
        margin-right: 47px;

        &.mobile{
            margin-right: 0;
            overflow: hidden;
        }

        img {
            max-width: 180px; /* La imagen se ajustará al ancho del contenedor */
            height: auto; /* La altura se ajustará proporcionalmente */
        }
    }

    .user-menu {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 25px; /* Espacio entre elementos del menú de usuario */
        
        .user-actions{
            display: flex;
            align-items: center;
            .avatar{
                align-items: center;
                display: flex;
                
                svg{
                    width: 24px;
                    height: 24px;
                    margin-right: 15px;
                }

                .avatar-image{
                    img{
                        width: 32px;
                        height: 32px;
                        margin-right: 15px;
                    }
                }
            }

            .user-name{
                overflow: hidden; 
                text-overflow: ellipsis; 
                overflow: hidden; 
                padding-right: 20px;
                

                .name-container{
                    color: #666;
                    font-family: Lato;
                    font-style: normal;
                    white-space: nowrap; 
                    font-size: $main-size-text;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    letter-spacing: -0.08px;
                }

                .arrow-icon{
                    color: #666666;
                    font-weight: bold;
                }
            }
        }
    }


    .btn-collapse-menu{
        font-size: 18px;
        margin-right: 20px;;
    }
    
    
}

.my-profile-link,
.close-session-link{
    color: #666;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.08px;
    white-space: nowrap; 

    .menu-icon{
        font-size: 1.3em;
        margin-right: 10px;
    }
}




