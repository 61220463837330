@import "../../../styles/theme";

.accounts-filter{
    
    .export-data-content{
        width: 100%;
        padding-bottom: 16px;


        .export-button-dropdown{
            display: flex;
            justify-content: flex-end;

            

            button:first-child {
                border-radius: $app-border-radius 0 0 $app-border-radius;
            }

            button:last-child {
                border-radius: 0 $app-border-radius $app-border-radius 0;
            }
        }
    }
}