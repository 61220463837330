.group-form-component{
    margin-left: 100px;
    margin-right: 100px;
    font-family: Montserrat;

    .float-button{
        float: right;
        right: 0;
        top: 20px;
        position: absolute; 
        max-width: 100px;
    }

    .special-extra-info{
        display: flex;
        align-items: center;

        .ant-form-item{
            padding: 0;
            margin: 0 0 0 10px;
        }
    }
    
    h2 {
        font-family: Montserrat;
        font-weight: bold;
    }
    
    .title-table {
        margin-top: 20px;
        font-family: Montserrat;
        font-weight: bold;
        font-size: 18px;
    }
    
    .title-with-button {
        display: flex;
        align-items: center;
        gap: 10px; /* Ajusta el espacio entre el texto y el botón según tus necesidades */
        flex-wrap: wrap; /* Permite que el contenido se envuelva en pantallas pequeñas */
    }
    
    .button-group {
        display: flex;
        justify-content: center;
        gap: 50px;
        margin-bottom: 20px;
    }
    
    .table {
        border-collapse: collapse;
        margin-top: 20px;
        font-family: Montserrat;
    }
    
    
    .button-class {
        align-items: center;
        font-family: Montserrat;
        width: 200px;
        background-color: var(--primary-app-color);
        border-radius: 0;
        margin-left: 20px;
    }
    
    .button-container {
        display: flex;
        justify-content: center;
    }
    
    .button-import {
        align-items: center;
        font-family: Montserrat;
        width: 200px;
        background-color: var(--secondary-app-color);
        border-radius: 0;
        margin-left: 20px;
    }
    
    .app-button {
        @media screen and (max-width: 768px) {
            width: 100%; /* Ajusta el ancho del botón para ocupar todo el espacio disponible */
            
        }
    

        &.short-button {
            width: auto; /* Ajusta según sea necesario */
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 10px; /* Ajusta el padding para mantener el botón con un buen aspecto */
        }
    }
    
    @media (max-width: 768px) {
        .title-table {
            margin-top: 0; /* Elimina el margen superior en pantallas pequeñas */
            margin-bottom: 10px; /* Añade un margen inferior para separar del botón */
            font-size: 16px; /* Ajusta el tamaño de la fuente si es necesario */
        }
        .title-with-button {
            justify-content: space-between; /* Asegura que el título y el botón se distribuyan correctamente */
            width: 100%; /* Asegura que el contenedor ocupe todo el ancho */
        }
        .app-button {
            margin-top: 10px; /* Añade un margen superior para separar del título */
            flex: 1 1 auto; /* Asegura que el botón tome espacio adecuadamente */
        }
    }
    

}