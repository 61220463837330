.client-number-component{
    text-align: center;
  
    .separator-client{
        border-top: 2px solid #2A2D33;
        min-width:  auto ;
        width: auto;
        margin: 5px auto;
        margin-left: 10%;
        margin-right: 10%;
        padding-bottom: 2px;

     
    }

    .label-number-client{
        color: #FFF;
    }
    .label-client{
        margin-top: 5px;
        margin-bottom: 5px;
        color: #9397A0
    }
   
    

  
}