.edit-profile-component{
    
    .ant-divider-inner-text{
        font-family: Roboto;
        font-size: 16px;
    }

    .ant-upload-select{
        span{
            padding: 10px;
        }
    }

    button{
        color: #FFF;
        font-family: Roboto;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.08px;
    }

    label{
        color: #C6C6C6 !important;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.2px;
    }

    .avatar-image{
        text-align: center;

        .save-avatar{
            width: 100%;
        }

        .avatar-uploader{
            margin-top: 10px;
        }

        small{
            font-size: 10px;
        }
    }

    .actions{
        margin-top: 10px;
        text-align: right;
    }
}